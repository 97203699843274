
// Generated by peggy v. 2.0.1 (ts-pegjs plugin v. 3.0.0 )
//
// https://peggyjs.org/   https://github.com/metadevpro/ts-pegjs

"use strict";

export interface FilePosition {
  offset: number;
  line: number;
  column: number;
}

export interface FileRange {
  start: FilePosition;
  end: FilePosition;
  source: string;
}

export interface LiteralExpectation {
  type: "literal";
  text: string;
  ignoreCase: boolean;
}

export interface ClassParts extends Array<string | ClassParts> {}

export interface ClassExpectation {
  type: "class";
  parts: ClassParts;
  inverted: boolean;
  ignoreCase: boolean;
}

export interface AnyExpectation {
  type: "any";
}

export interface EndExpectation {
  type: "end";
}

export interface OtherExpectation {
  type: "other";
  description: string;
}

export type Expectation = LiteralExpectation | ClassExpectation | AnyExpectation | EndExpectation | OtherExpectation;

function peg$padEnd(str: string, targetLength: number, padString: string) {
  padString = padString || ' ';
  if (str.length > targetLength) {
    return str;
  }
  targetLength -= str.length;
  padString += padString.repeat(targetLength);
  return str + padString.slice(0, targetLength);
}

export class PeggySyntaxError extends Error {
  public static buildMessage(expected: Expectation[], found: string | null) {
    function hex(ch: string): string {
      return ch.charCodeAt(0).toString(16).toUpperCase();
    }

    function literalEscape(s: string): string {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/"/g,  "\\\"")
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g,            (ch) => "\\x0" + hex(ch) )
        .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x"  + hex(ch) );
    }

    function classEscape(s: string): string {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/\]/g, "\\]")
        .replace(/\^/g, "\\^")
        .replace(/-/g,  "\\-")
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g,            (ch) => "\\x0" + hex(ch) )
        .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x"  + hex(ch) );
    }

    function describeExpectation(expectation: Expectation) {
      switch (expectation.type) {
        case "literal":
          return "\"" + literalEscape(expectation.text) + "\"";
        case "class":
          const escapedParts = expectation.parts.map((part) => {
            return Array.isArray(part)
              ? classEscape(part[0] as string) + "-" + classEscape(part[1] as string)
              : classEscape(part);
          });

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        case "any":
          return "any character";
        case "end":
          return "end of input";
        case "other":
          return expectation.description;
      }
    }

    function describeExpected(expected1: Expectation[]) {
      const descriptions = expected1.map(describeExpectation);
      let i: number;
      let j: number;

      descriptions.sort();

      if (descriptions.length > 0) {
        for (i = 1, j = 1; i < descriptions.length; i++) {
          if (descriptions[i - 1] !== descriptions[i]) {
            descriptions[j] = descriptions[i];
            j++;
          }
        }
        descriptions.length = j;
      }

      switch (descriptions.length) {
        case 1:
          return descriptions[0];

        case 2:
          return descriptions[0] + " or " + descriptions[1];

        default:
          return descriptions.slice(0, -1).join(", ")
            + ", or "
            + descriptions[descriptions.length - 1];
      }
    }

    function describeFound(found1: string | null) {
      return found1 ? "\"" + literalEscape(found1) + "\"" : "end of input";
    }

    return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
  }

  public message: string;
  public expected: Expectation[];
  public found: string | null;
  public location: FileRange;
  public name: string;

  constructor(message: string, expected: Expectation[], found: string | null, location: FileRange) {
    super();
    this.message = message;
    this.expected = expected;
    this.found = found;
    this.location = location;
    this.name = "PeggySyntaxError";

    if (typeof (Object as any).setPrototypeOf === "function") {
      (Object as any).setPrototypeOf(this, PeggySyntaxError.prototype);
    } else {
      (this as any).__proto__ = PeggySyntaxError.prototype;
    }
    if (typeof (Error as any).captureStackTrace === "function") {
      (Error as any).captureStackTrace(this, PeggySyntaxError);
    }
  }

  format(sources: { grammarSource?: string; text: string }[]): string {
    let str = 'Error: ' + this.message;
    if (this.location) {
      let src: string[] | null = null;
      let k;
      for (k = 0; k < sources.length; k++) {
        if (sources[k].grammarSource === this.location.source) {
          src = sources[k].text.split(/\r\n|\n|\r/g);
          break;
        }
      }
      let s = this.location.start;
      let loc = this.location.source + ':' + s.line + ':' + s.column;
      if (src) {
        let e = this.location.end;
        let filler = peg$padEnd('', s.line.toString().length, ' ');
        let line = src[s.line - 1];
        let last = s.line === e.line ? e.column : line.length + 1;
        str += '\n --> ' + loc + '\n' + filler + ' |\n' + s.line + ' | ' + line + '\n' + filler + ' | ' +
          peg$padEnd('', s.column - 1, ' ') +
          peg$padEnd('', last - s.column, '^');
      } else {
        str += '\n at ' + loc;
      }
    }
    return str;
  }
}

function peg$parse(input: string, options?: ParseOptions) {
  options = options !== undefined ? options : {};

  const peg$FAILED: Readonly<any> = {};
  const peg$source = options.grammarSource;

  const peg$startRuleFunctions: {[id: string]: any} = { ChordSheet: peg$parseChordSheet };
  let peg$startRuleFunction: () => any = peg$parseChordSheet;

  const peg$c0 = function(metadataLines: any, lines: any): any {
        return {
          type: "chordSheet",
          lines: [
            ...metadataLines,
            ...lines,
          ]
        };
      };
  const peg$c1 = function(newLine: any, items: any, trailingItem: any): any {
      const hasEmptyLine = newLine?.length > 0;
      const emptyLines = hasEmptyLine ? [{ type: "line", items: [] }] : [];
      return [...emptyLines, ...items, trailingItem];
    };
  const peg$c2 = function(item: any): any {
      return item;
    };
  const peg$c3 = function(item: any): any {
      if (item.type === "chordsLine") {
        return {
          type: "line",
          items: item.items.map((item) => {
            const chordLyricsPair = {
              type: "chordLyricsPair"
            };

            if (item.type === "chord") {
              return {
                ...chordLyricsPair,
                chord: item
              };
            }

            return {
              ...chordLyricsPair,
              chords: item.value
            };
          })
        };
      }

      return item;
    };
  const peg$c4 = function(chordsLine: any, lyrics: any): any {
        const chords = chordsLine.items;

        const chordLyricsPairs = chords.map((chord, i) => {
          const nextChord = chords[i + 1];
          const start = chord.column - 1;
          const end = nextChord ? nextChord.column - 1 : lyrics.length;
          const pairLyrics = lyrics.substring(start, end);
          const result = /(\s+)(\S+)/.exec(pairLyrics);
          const secondWordPosition = result ? (result.index + result[1].length) : null;

          const chordData = (chord.type === "chord") ? { chord } : { chords: chord.value };

          if (secondWordPosition && secondWordPosition < end) {
            return [
              { type: "chordLyricsPair", ...chordData, lyrics: pairLyrics.substring(0, secondWordPosition) },
              { type: "chordLyricsPair", chords: "", lyrics: pairLyrics.substring(secondWordPosition) },
            ];
          }

          return { type: "chordLyricsPair", ...chordData, lyrics: pairLyrics };
        }).flat();

        const firstChord = chords[0];

        if (firstChord && firstChord.column > 1) {
        	const firstChordPosition = firstChord.column;

          if (firstChordPosition > 0) {
            chordLyricsPairs.unshift({
              type: "chordLyricsPair",
              chords: "",
              lyrics: lyrics.substring(0, firstChordPosition - 1),
            });
          }
        }

        return { type: "line", items: chordLyricsPairs };
      };
  const peg$c5 = function(items: any): any {
        return {
          type: "chordsLine",
          items
        };
      };
  const peg$c6 = function(symbol: any): any {
        return symbol;
      };
  const peg$c7 = "/";
  const peg$c8 = peg$literalExpectation("/", false);
  const peg$c9 = "|";
  const peg$c10 = peg$literalExpectation("|", false);
  const peg$c11 = "-";
  const peg$c12 = peg$literalExpectation("-", false);
  const peg$c13 = function(symbol: any): any {
        return {
          type: "symbol",
          value: symbol,
          column: location().start.column
        };
      };
  const peg$c14 = function(lyrics: any): any {
    	if (lyrics.length === 0) {
        return { type: "line", items: [] };
      }

      return {
        type: "line",
        items: [
          { type: "chordLyricsPair", chords: "", lyrics }
        ]
      };
    };
  const peg$c15 = function(chord: any): any {
        return chord;
      };
  const peg$c16 = function(line: any): any {
        return {
          type: "line",
          items: [
            { type: "tag", name: "comment", value: line }
          ]
        };
      };
  const peg$c17 = "verse";
  const peg$c18 = peg$literalExpectation("verse", true);
  const peg$c19 = "chorus";
  const peg$c20 = peg$literalExpectation("chorus", true);
  const peg$c21 = "bridge";
  const peg$c22 = peg$literalExpectation("bridge", true);
  const peg$c23 = "tag";
  const peg$c24 = peg$literalExpectation("tag", true);
  const peg$c25 = "interlude";
  const peg$c26 = peg$literalExpectation("interlude", true);
  const peg$c27 = "instrumental";
  const peg$c28 = peg$literalExpectation("instrumental", true);
  const peg$c29 = "intro";
  const peg$c30 = peg$literalExpectation("intro", true);
  const peg$c31 = /^[^\n\r]/;
  const peg$c32 = peg$classExpectation(["\n", "\r"], true, false);
  const peg$c33 = function(pairs: any, trailingPair: any): any {
        return [...pairs, trailingPair]
          .filter(x => x)
          .map(([key, value]) => ({
            type: "line",
            items: [
              { type: "tag", name: key, value },
            ],
          }));
      };
  const peg$c34 = function(key: any, value: any): any {
        return {
          type: "line",
          items: [
            { type: "tag", name: key, value },
          ],
        }
      };
  const peg$c35 = function(pair: any): any {
        return pair;
      };
  const peg$c36 = "{";
  const peg$c37 = peg$literalExpectation("{", false);
  const peg$c38 = "}";
  const peg$c39 = peg$literalExpectation("}", false);
  const peg$c40 = function(pair: any): any {
      return pair;
    };
  const peg$c41 = function(key: any, value: any): any {
      return [key, value];
    };
  const peg$c42 = ":";
  const peg$c43 = peg$literalExpectation(":", false);
  const peg$c44 = /^[a-zA-Z0-9\-_]/;
  const peg$c45 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "-", "_"], false, false);
  const peg$c46 = /^[^\n\r}]/;
  const peg$c47 = peg$classExpectation(["\n", "\r", "}"], true, false);
  const peg$c48 = "---";
  const peg$c49 = peg$literalExpectation("---", false);
  const peg$c50 = peg$otherExpectation("whitespace");
  const peg$c51 = /^[ \t]/;
  const peg$c52 = peg$classExpectation([" ", "\t"], false, false);
  const peg$c53 = "\n";
  const peg$c54 = peg$literalExpectation("\n", false);
  const peg$c55 = "\r";
  const peg$c56 = peg$literalExpectation("\r", false);
  const peg$c57 = function(chord: any): any {
        return { type: "chord", ...chord, column: location().start.column };
      };
  const peg$c58 = "#";
  const peg$c59 = peg$literalExpectation("#", false);
  const peg$c60 = "b";
  const peg$c61 = peg$literalExpectation("b", false);
  const peg$c62 = /^[a-zA-Z0-9()#+]/;
  const peg$c63 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "(", ")", "#", "+"], false, false);
  const peg$c64 = function(root: any, modifier: any, suffix: any, bass: any): any {
    	  return { base: root, modifier, suffix, ...bass, chordType: "symbol" };
      };
  const peg$c65 = /^[A-Ga-g]/;
  const peg$c66 = peg$classExpectation([["A", "G"], ["a", "g"]], false, false);
  const peg$c67 = function(root: any, modifier: any): any {
        return { bassBase: root, bassModifier: modifier };
      };
  const peg$c68 = function(modifier: any, root: any, suffix: any, bass: any): any {
        return { base: root, modifier, suffix, ...bass, chordType: "numeral" };
      };
  const peg$c69 = "III";
  const peg$c70 = peg$literalExpectation("III", false);
  const peg$c71 = "iii";
  const peg$c72 = peg$literalExpectation("iii", false);
  const peg$c73 = "VII";
  const peg$c74 = peg$literalExpectation("VII", false);
  const peg$c75 = "vii";
  const peg$c76 = peg$literalExpectation("vii", false);
  const peg$c77 = "II";
  const peg$c78 = peg$literalExpectation("II", false);
  const peg$c79 = "ii";
  const peg$c80 = peg$literalExpectation("ii", false);
  const peg$c81 = "IV";
  const peg$c82 = peg$literalExpectation("IV", false);
  const peg$c83 = "iv";
  const peg$c84 = peg$literalExpectation("iv", false);
  const peg$c85 = "VI";
  const peg$c86 = peg$literalExpectation("VI", false);
  const peg$c87 = "vi";
  const peg$c88 = peg$literalExpectation("vi", false);
  const peg$c89 = "I";
  const peg$c90 = peg$literalExpectation("I", false);
  const peg$c91 = "i";
  const peg$c92 = peg$literalExpectation("i", false);
  const peg$c93 = "V";
  const peg$c94 = peg$literalExpectation("V", false);
  const peg$c95 = "v";
  const peg$c96 = peg$literalExpectation("v", false);
  const peg$c97 = function(modifier: any, root: any): any {
        return { bassBase: root, bassModifier: modifier };
      };
  const peg$c98 = function(modifier: any, root: any, suffix: any, bass: any): any {
        return { base: root, modifier, suffix, ...bass, chordType: "numeric" };
      };
  const peg$c99 = /^[1-7]/;
  const peg$c100 = peg$classExpectation([["1", "7"]], false, false);

  let peg$currPos = 0;
  let peg$savedPos = 0;
  const peg$posDetailsCache = [{ line: 1, column: 1 }];
  let peg$maxFailPos = 0;
  let peg$maxFailExpected: Expectation[] = [];
  let peg$silentFails = 0;

  let peg$result;

  if (options.startRule !== undefined) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text(): string {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location(): FileRange {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description: string, location1?: FileRange) {
    location1 = location1 !== undefined
      ? location1
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location1
    );
  }

  function error(message: string, location1?: FileRange) {
    location1 = location1 !== undefined
      ? location1
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location1);
  }

  function peg$literalExpectation(text1: string, ignoreCase: boolean): LiteralExpectation {
    return { type: "literal", text: text1, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts: ClassParts, inverted: boolean, ignoreCase: boolean): ClassExpectation {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation(): AnyExpectation {
    return { type: "any" };
  }

  function peg$endExpectation(): EndExpectation {
    return { type: "end" };
  }

  function peg$otherExpectation(description: string): OtherExpectation {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos: number) {
    let details = peg$posDetailsCache[pos];
    let p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  function peg$computeLocation(startPos: number, endPos: number): FileRange {
    const startPosDetails = peg$computePosDetails(startPos);
    const endPosDetails = peg$computePosDetails(endPos);

    return {
      source: peg$source,
      start: {
        offset: startPos,
        line: startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line: endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected1: Expectation) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected1);
  }

  function peg$buildSimpleError(message: string, location1: FileRange) {
    return new PeggySyntaxError(message, [], "", location1);
  }

  function peg$buildStructuredError(expected1: Expectation[], found: string | null, location1: FileRange) {
    return new PeggySyntaxError(
      PeggySyntaxError.buildMessage(expected1, found),
      expected1,
      found,
      location1
    );
  }

  function peg$parseChordSheet(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseMetadata();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseChordSheetContents();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c0(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseChordSheetContents(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseNewLine();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseChordSheetItemWithNewLine();
      while (s3 as any !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseChordSheetItemWithNewLine();
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseChordSheetItem();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1(s1, s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseChordSheetItemWithNewLine(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseChordSheetItem();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseNewLine();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c2(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseChordSheetItem(): any {
    let s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseDirectionLine();
    if (s1 as any === peg$FAILED) {
      s1 = peg$parseInlineMetadata();
      if (s1 as any === peg$FAILED) {
        s1 = peg$parseChordLyricsLines();
        if (s1 as any === peg$FAILED) {
          s1 = peg$parseChordsLine();
          if (s1 as any === peg$FAILED) {
            s1 = peg$parseLyricsLine();
          }
        }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c3(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseChordLyricsLines(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseChordsLine();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseNewLine();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseNonEmptyLyrics();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c4(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseChordsLine(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseRhythmSymbolWithSpacing();
    if (s2 as any === peg$FAILED) {
      s2 = peg$parseChordWithSpacing();
    }
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseRhythmSymbolWithSpacing();
        if (s2 as any === peg$FAILED) {
          s2 = peg$parseChordWithSpacing();
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c5(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseRhythmSymbolWithSpacing(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseRhythmSymbol();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c6(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseRhythmSymbol(): any {
    let s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 47) {
      s1 = peg$c7;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c8); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 124) {
        s1 = peg$c9;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c10); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 45) {
          s1 = peg$c11;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c12); }
        }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c13(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseLyricsLine(): any {
    let s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseLyrics();
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c14(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseLyrics(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseWordChar();
    while (s2 as any !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parseWordChar();
    }
    if (s1 as any !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parseNonEmptyLyrics(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseWordChar();
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseWordChar();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parseChordWithSpacing(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseChord();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c15(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseDirectionLine(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$currPos;
    s3 = peg$parse_();
    if (s3 as any !== peg$FAILED) {
      s4 = peg$parseKeyword();
      if (s4 as any !== peg$FAILED) {
        s5 = peg$parse_();
        if (s5 as any !== peg$FAILED) {
          s6 = [];
          s7 = peg$parseWordChar();
          while (s7 as any !== peg$FAILED) {
            s6.push(s7);
            s7 = peg$parseWordChar();
          }
          if (s6 as any !== peg$FAILED) {
            s7 = peg$parse_();
            if (s7 as any !== peg$FAILED) {
              s3 = [s3, s4, s5, s6, s7];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 as any !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c16(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseKeyword(): any {
    let s0;

    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c17) {
      s0 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c18); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c19) {
        s0 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c20); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 6).toLowerCase() === peg$c21) {
          s0 = input.substr(peg$currPos, 6);
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c22); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 3).toLowerCase() === peg$c23) {
            s0 = input.substr(peg$currPos, 3);
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c24); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 9).toLowerCase() === peg$c25) {
              s0 = input.substr(peg$currPos, 9);
              peg$currPos += 9;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c26); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 12).toLowerCase() === peg$c27) {
                s0 = input.substr(peg$currPos, 12);
                peg$currPos += 12;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c28); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 5).toLowerCase() === peg$c29) {
                  s0 = input.substr(peg$currPos, 5);
                  peg$currPos += 5;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c30); }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseWordChar(): any {
    let s0;

    if (peg$c31.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c32); }
    }

    return s0;
  }

  function peg$parseMetadata(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseMetadataPairWithNewLine();
    while (s2 as any !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parseMetadataPairWithNewLine();
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseMetadataPair();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseMetadataSeparator();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c33(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseInlineMetadata(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseMetadataKey();
    if (s2 as any !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseColon();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = peg$parseMetadataValue();
            if (s6 as any !== peg$FAILED) {
              s5 = input.substring(s5, peg$currPos);
            } else {
              s5 = s6;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c34(s1, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetadataPairWithNewLine(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseMetadataPair();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseNewLine();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c35(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetadataPair(): any {
    let s0;

    s0 = peg$parseMetadataPairWithBrackets();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseMetadataPairWithoutBrackets();
    }

    return s0;
  }

  function peg$parseMetadataPairWithBrackets(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c36;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c37); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseMetadataPairWithoutBrackets();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 as any !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 125) {
              s5 = peg$c38;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c39); }
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c40(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetadataPairWithoutBrackets(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseMetadataKey();
    if (s2 as any !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseColon();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = peg$parseMetadataValue();
            if (s6 as any !== peg$FAILED) {
              s5 = input.substring(s5, peg$currPos);
            } else {
              s5 = s6;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c41(s1, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseColon(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 58) {
      s0 = peg$c42;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }

    return s0;
  }

  function peg$parseMetadataKey(): any {
    let s0, s1;

    s0 = [];
    if (peg$c44.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c45); }
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        if (peg$c44.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c45); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetadataValue(): any {
    let s0, s1;

    s0 = [];
    if (peg$c46.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c47); }
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        if (peg$c46.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c47); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetadataSeparator(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c48) {
      s1 = peg$c48;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c49); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseNewLine();
      if (s2 as any !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parse_(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = [];
    if (peg$c51.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c52); }
    }
    while (s1 as any !== peg$FAILED) {
      s0.push(s1);
      if (peg$c51.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c52); }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c50); }
    }

    return s0;
  }

  function peg$parseNewLine(): any {
    let s0;

    s0 = peg$parseCarriageReturn();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseLineFeed();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parseCarriageReturnLineFeed();
      }
    }

    return s0;
  }

  function peg$parseCarriageReturnLineFeed(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseCarriageReturn();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseLineFeed();
      if (s2 as any !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLineFeed(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 10) {
      s0 = peg$c53;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c54); }
    }

    return s0;
  }

  function peg$parseCarriageReturn(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 13) {
      s0 = peg$c55;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c56); }
    }

    return s0;
  }

  function peg$parseChord(): any {
    let s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseNumeral();
    if (s1 as any === peg$FAILED) {
      s1 = peg$parseNumeric();
      if (s1 as any === peg$FAILED) {
        s1 = peg$parseChordSymbol();
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c57(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseChordModifier(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 35) {
      s0 = peg$c58;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c59); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 98) {
        s0 = peg$c60;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c61); }
      }
    }

    return s0;
  }

  function peg$parseChordSuffix(): any {
    let s0, s1;

    s0 = [];
    if (peg$c62.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c63); }
    }
    while (s1 as any !== peg$FAILED) {
      s0.push(s1);
      if (peg$c62.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c63); }
      }
    }

    return s0;
  }

  function peg$parseChordSymbol(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseChordSymbolRoot();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseChordModifier();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseChordSuffix();
        if (s4 as any !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseChordSymbolBass();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c64(s1, s2, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseChordSymbolRoot(): any {
    let s0;

    if (peg$c65.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c66); }
    }

    return s0;
  }

  function peg$parseChordSymbolBass(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 47) {
      s1 = peg$c7;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c8); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseChordSymbolRoot();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseChordModifier();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c67(s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNumeral(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseChordModifier();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseNumeralRoot();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseChordSuffix();
        if (s4 as any !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseNumeralBass();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c68(s1, s2, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNumeralRoot(): any {
    let s0;

    if (input.substr(peg$currPos, 3) === peg$c69) {
      s0 = peg$c69;
      peg$currPos += 3;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c70); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c71) {
        s0 = peg$c71;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c72); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c73) {
          s0 = peg$c73;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c74); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c75) {
            s0 = peg$c75;
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c76); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c77) {
              s0 = peg$c77;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c78); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c79) {
                s0 = peg$c79;
                peg$currPos += 2;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c80); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c81) {
                  s0 = peg$c81;
                  peg$currPos += 2;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c82); }
                }
                if (s0 as any === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c83) {
                    s0 = peg$c83;
                    peg$currPos += 2;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c84); }
                  }
                  if (s0 as any === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c85) {
                      s0 = peg$c85;
                      peg$currPos += 2;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c86); }
                    }
                    if (s0 as any === peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c87) {
                        s0 = peg$c87;
                        peg$currPos += 2;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c88); }
                      }
                      if (s0 as any === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 73) {
                          s0 = peg$c89;
                          peg$currPos++;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c90); }
                        }
                        if (s0 as any === peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 105) {
                            s0 = peg$c91;
                            peg$currPos++;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c92); }
                          }
                          if (s0 as any === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 86) {
                              s0 = peg$c93;
                              peg$currPos++;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c94); }
                            }
                            if (s0 as any === peg$FAILED) {
                              if (input.charCodeAt(peg$currPos) === 118) {
                                s0 = peg$c95;
                                peg$currPos++;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c96); }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseNumeralBass(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 47) {
      s1 = peg$c7;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c8); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseChordModifier();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseNumeralRoot();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c97(s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNumeric(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseChordModifier();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseNumericRoot();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseChordSuffix();
        if (s4 as any !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseNumericBass();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c98(s1, s2, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNumericRoot(): any {
    let s0;

    if (peg$c99.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c100); }
    }

    return s0;
  }

  function peg$parseNumericBass(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 47) {
      s1 = peg$c7;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c8); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseChordModifier();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseNumericRoot();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c97(s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

export interface ParseOptions {
  filename?: string;
  startRule?: string;
  tracer?: any;
  [key: string]: any;
}
export type ParseFunction = (input: string, options?: ParseOptions) => any;
export const parse: ParseFunction = peg$parse;

