const mapping: Record<string, string> = {
  "2": "2",
  "4": "sus",
  "5": "5",
  "6": "6",
  "7": "7",
  "9": "9",
  "11": "11",
  "13": "13",
  "42": "4(2)",
  "69": "6(9)",
  "(#4)": "(#4)",
  "#4": "(#4)",
  "+4": "(#4)",
  "(+4)": "(#4)",
  "(11)": "(11)",
  "add11": "(11)",
  "(add11)": "(11)",
  "(13)": "(13)",
  "add13": "(13)",
  "(add13)": "(13)",
  "(2)": "(2)",
  "add2": "(2)",
  "(add2)": "(2)",
  "(4)": "(4)",
  "add4": "(4)",
  "(add4)": "(4)",
  "sus(4)": "(4)",
  "(6)": "(6)",
  "add6": "(6)",
  "(add6)": "(6)",
  "(7)": "(7)",
  "(9)": "(9)",
  "add9": "(9)",
  "(add9)": "(9)",
  "(b5)": "(b5)",
  "-5": "(b5)",
  "(-5)": "(b5)",
  "b5": "(b5)",
  "2(6)": "2(6)",
  "(b6)": "(b6)",
  "(unis)": "(unis)",
  "unis": "(unis)",
  "[blank]": "[blank]",
  "maj": "[blank]",
  "major": "[blank]",
  "M": "[blank]",
  "ma": "[blank]",
  "Ma": "[blank]",
  "Majj": "[blank]",
  "+": "+",
  "aug": "+",
  "(#5)": "+",
  "#5": "+",
  "+5": "+",
  "(+5)": "+",
  "x": "+",
  "dom11": "11",
  "dom 11": "11",
  "11(#5)": "11(#5)",
  "11#5": "11(#5)",
  "11+5": "11(#5)",
  "11(+5)": "11(#5)",
  "11(#9)": "11(#9)",
  "11#9": "11(#9)",
  "11+9": "11(#9)",
  "11(+9)": "11(#9)",
  "11(b13)": "11(b13)",
  "11b13": "11(b13)",
  "11-13": "11(b13)",
  "11(-13)": "11(b13)",
  "11(b5)": "11(b5)",
  "11b5": "11(b5)",
  "11-5": "11(b5)",
  "11(-5)": "11(b5)",
  "11(b9)": "11(b9)",
  "11b9": "11(b9)",
  "11-9": "11(b9)",
  "11(-9)": "11(b9)",
  "11sus4": "11sus4",
  "11sus": "11sus4",
  "m11sus4": "11sus4",
  "m11sus": "11sus4",
  "dom13": "13",
  "dom 13": "13",
  "13(#11)": "13(#11)",
  "13#11": "13(#11)",
  "13+11": "13(#11)",
  "13(+11)": "13(#11)",
  "13(#5)": "13(#5)",
  "13#5": "13(#5)",
  "13+5": "13(#5)",
  "13(+5)": "13(#5)",
  "13(#9)": "13(#9)",
  "13#9": "13(#9)",
  "13(+9)": "13(#9)",
  "13+9": "13(#9)",
  "13(#9#5)": "13(#9#5)",
  "13#9#5": "13(#9#5)",
  "13(+9+5)": "13(#9#5)",
  "13+9+5": "13(#9#5)",
  "13(#9b5)": "13(#9b5)",
  "13#9b5": "13(#9b5)",
  "13(+9-5)": "13(#9b5)",
  "13+9-5": "13(#9b5)",
  "13(add4)": "13(add4)",
  "13(b5)": "13(b5)",
  "13b5": "13(b5)",
  "13-5": "13(b5)",
  "13(-5)": "13(b5)",
  "13(b9)": "13(b9)",
  "13b9": "13(b9)",
  "13-9": "13(b9)",
  "13(-9)": "13(b9)",
  "13(b9#5)": "13(b9#5)",
  "13b9#5": "13(b9#5)",
  "13-9+5": "13(b9#5)",
  "13(-9+5)": "13(b9#5)",
  "13(b9b5)": "13(b9b5)",
  "13b9b5": "13(b9b5)",
  "13-9-5": "13(b9b5)",
  "13(-9-5)": "13(b9b5)",
  "13sus4": "13sus4",
  "13sus": "13sus4",
  "m13sus4": "13sus4",
  "m13sus": "13sus4",
  "sus2": "2",
  "add9(no3)": "2",
  "2(#11)": "2(#11)",
  "2(#4)": "2(#4)",
  "2+4": "2(#4)",
  "2#4": "2(#4)",
  "2(+4)": "2(#4)",
  "(#4)2": "2(#4)",
  "2(#4)(#42)(2#4)": "2(#4)(#42)(2#4)",
  "2(+7)": "2(+7)",
  "2(4)": "2(4)",
  "sus2(4)": "2(4)",
  "2(ma7)": "2(ma7)",
  "2(no3)": "2(no3)",
  "4(2)": "4(2)",
  "sus42": "4(2)",
  "no3": "5",
  "(no3)": "5",
  "(no 3)": "5",
  "(no 3rd)": "5",
  "5(no3)": "5",
  "6(2)": "6(2)",
  "6(b9)": "6(b9)",
  "6(no3)": "6(no3)",
  "6(9)": "6(9)",
  "6(add9)": "6(9)",
  "dom7": "7",
  "dom 7": "7",
  "dom 7th": "7",
  "7(#11)": "7(#11)",
  "7#11": "7(#11)",
  "7+11": "7(#11)",
  "7(+11)": "7(#11)",
  "7(#5)": "7(#5)",
  "aug7": "7(#5)",
  "7#5": "7(#5)",
  "7+5": "7(#5)",
  "7(+5)": "7(#5)",
  "x7": "7(#5)",
  "aug 7": "7(#5)",
  "aug 7th": "7(#5)",
  "7(#5#11)": "7(#5#11)",
  "7#5#11": "7(#5#11)",
  "7+5+11": "7(#5#11)",
  "7(+5+11)": "7(#5#11)",
  "7(#9)": "7(#9)",
  "7#9": "7(#9)",
  "7+9": "7(#9)",
  "7(+9)": "7(#9)",
  "7(#9#5)": "7(#9#5)",
  "7(#5#9)": "7(#9#5)",
  "7#5#9": "7(#9#5)",
  "7+5+9": "7(#9#5)",
  "7(+5+9)": "7(#9#5)",
  "7(#9b13)": "7(#9b13)",
  "7#9b13": "7(#9b13)",
  "7(+9-13)": "7(#9b13)",
  "7+9-13": "7(#9b13)",
  "7(b13#9)": "7(#9b13)",
  "7b13#9": "7(#9b13)",
  "7-13+9": "7(#9b13)",
  "7(-13+9)": "7(#9b13)",
  "7(#9b5)": "7(#9b5)",
  "7(6)": "7(6)",
  "7(b13)": "7(b13)",
  "7b13": "7(b13)",
  "7-13": "7(b13)",
  "7(-13)": "7(b13)",
  "7(b5)": "7(b5)",
  "7b5": "7(b5)",
  "7-5": "7(b5)",
  "7(-5)": "7(b5)",
  "7(b5#11)": "7(b5#11)",
  "7b5#11": "7(b5#11)",
  "7-5+11": "7(b5#11)",
  "7(-5+11)": "7(b5#11)",
  "7(b5#9)": "7(b5#9)",
  "7b5#9": "7(b5#9)",
  "7-5+9": "7(b5#9)",
  "7(-5+9)": "7(b5#9)",
  "7(b5b9)": "7(b5b9)",
  "7b5b9": "7(b5b9)",
  "7-5-9": "7(b5b9)",
  "7(-5-9)": "7(b5b9)",
  "7(b9)": "7(b9)",
  "7b9": "7(b9)",
  "7-9": "7(b9)",
  "7(-9)": "7(b9)",
  "7(b9#5)": "7(b9#5)",
  "7(#5b9)": "7(b9#5)",
  "7#5b9": "7(b9#5)",
  "7+5-9": "7(b9#5)",
  "7(+5-9)": "7(b9#5)",
  "7(b9b13)": "7(b9b13)",
  "7b9b13": "7(b9b13)",
  "7-9-13": "7(b9b13)",
  "7(-9-13)": "7(b9b13)",
  "7(b13b9)": "7(b9b13)",
  "7b13b9": "7(b9b13)",
  "7-13-9": "7(b9b13)",
  "7(-13-9)": "7(b9b13)",
  "7(b9b5)": "7(b9b5)",
  "7(no3)": "7(no3)",
  "7aug5": "7aug5",
  "7b9sus4": "7b9sus4",
  "7sus(6)": "7sus(6)",
  "7sus(b9)": "7sus(b9)",
  "7sus4": "7sus4",
  "7sus": "7sus4",
  "dom9": "9",
  "dom 9": "9",
  "9(#11)": "9(#11)",
  "9#11": "9(#11)",
  "9+11": "9(#11)",
  "9(+11)": "9(#11)",
  "9(#5)": "9(#5)",
  "9#5": "9(#5)",
  "9+5": "9(#5)",
  "9(+5)": "9(#5)",
  "aug9": "9(#5)",
  "x9": "9(#5)",
  "9(b13)": "9(b13)",
  "9b13": "9(b13)",
  "9-13": "9(b13)",
  "9(-13)": "9(b13)",
  "9(b5)": "9(b5)",
  "9b5": "9(b5)",
  "9-5": "9(b5)",
  "9(-5)": "9(b5)",
  "9aug": "9aug",
  "9sus4": "9sus4",
  "9sus": "9sus4",
  "m9sus4": "9sus4",
  "m9sus": "9sus4",
  "b69(#11)": "b69(#11)",
  "b69sus": "b69sus",
  "b9sus": "b9sus",
  "dim": "dim",
  "m(b5)": "dim",
  "mb5": "dim",
  "m-5": "dim",
  "m(-5)": "dim",
  "-(b5)": "dim",
  "-b5": "dim",
  "dim7": "dim7",
  "o7": "dim7",
  "dim 7": "dim7",
  "dim 7th": "dim7",
  "m": "m",
  "mi": "m",
  "min": "m",
  "minor": "m",
  "-": "m",
  "m(11)": "m(11)",
  "m(add11)": "m(11)",
  "m(4)": "m(4)",
  "m(add4)": "m(4)",
  "m(9)": "m(9)",
  "m(add9)": "m(9)",
  "m(ma7)": "m(ma7)",
  "m(M7)": "m(ma7)",
  "mi(maj7)": "m(ma7)",
  "min(maj7)": "m(ma7)",
  "m(maj7)": "m(ma7)",
  "m(+7)": "m(ma7)",
  "m+7": "m(ma7)",
  "m(ma9)": "m(ma9)",
  "m(M9)": "m(ma9)",
  "mi(maj9)": "m(ma9)",
  "min(maj9)": "m(ma9)",
  "m(maj9)": "m(ma9)",
  "m(+9)": "m(ma9)",
  "m+9": "m(ma9)",
  "m(no5)": "m(no5)",
  "m11": "m11",
  "mi11": "m11",
  "min11": "m11",
  "-11": "m11",
  "m11(#5)": "m11(#5)",
  "m11#5": "m11(#5)",
  "m11+5": "m11(#5)",
  "m11(+5)": "m11(#5)",
  "-11(#5)": "m11(#5)",
  "-11#5": "m11(#5)",
  "m9+5": "m9(#5)",
  "-11(+5)": "m11(#5)",
  "m11(#9)": "m11(#9)",
  "m11#9": "m11(#9)",
  "m11+9": "m11(#9)",
  "m11(+9)": "m11(#9)",
  "-11(#9)": "m11(#9)",
  "-11#9": "m11(#9)",
  "-11+9": "m11(#9)",
  "-11(+9)": "m11(#9)",
  "m11(#9#5)": "m11(#9#5)",
  "m11(#5#9)": "m11(#9#5)",
  "m11#5#9": "m11(#9#5)",
  "m11+5+9": "m11(#9#5)",
  "m11(+5+9)": "m11(#9#5)",
  "-11(#5#9)": "m11(#9#5)",
  "-11#5#9": "m11(#9#5)",
  "m9+5+9": "m11(#9#5)",
  "-11(+5+9)": "m11(#9#5)",
  "m11(#9b13)": "m11(#9b13)",
  "m11#9b13": "m11(#9b13)",
  "m11+9-13": "m11(#9b13)",
  "m11(+9-13)": "m11(#9b13)",
  "-11(#9b13)": "m11(#9b13)",
  "-11#9b13": "m11(#9b13)",
  "-11+9-13": "m11(#9b13)",
  "-11(+9-13)": "m11(#9b13)",
  "m11(b13)": "m11(b13)",
  "m11b13": "m11(b13)",
  "m11-13": "m11(b13)",
  "m11(-13)": "m11(b13)",
  "-11(b13)": "m11(b13)",
  "-11b13": "m11(b13)",
  "-11-13": "m11(b13)",
  "-11(-13)": "m11(b13)",
  "m11(b13#5)": "m11(b13#5)",
  "m11(#5b13)": "m11(b13#5)",
  "m11#5b13": "m11(b13#5)",
  "m11+5-13": "m11(b13#5)",
  "m11(+5-13)": "m11(b13#5)",
  "-11(#5b13)": "m11(b13#5)",
  "-11#5b13": "m11(b13#5)",
  "-11+5-13": "m11(b13#5)",
  "-11(+5-13)": "m11(b13#5)",
  "m11(b5)": "m11(b5)",
  "m11b5": "m11(b5)",
  "m11-5": "m11(b5)",
  "m11(-5)": "m11(b5)",
  "-11(b5)": "m11(b5)",
  "-11b5": "m11(b5)",
  "-9-5": "m9(b5)",
  "-11(-5)": "m11(b5)",
  "m11(b5#9)": "m11(b5#9)",
  "m11b5#9": "m11(b5#9)",
  "m11-5+9": "m11(b5#9)",
  "m11(-5+9)": "m11(b5#9)",
  "-11(b5#9)": "m11(b5#9)",
  "-11b5#9": "m11(b5#9)",
  "-11-5+9": "m11(b5#9)",
  "-11(-5+9)": "m11(b5#9)",
  "m11(b5b13)": "m11(b5b13)",
  "m11b5b13": "m11(b5b13)",
  "m11(-5-13)": "m11(b5b13)",
  "m11-5-13": "m11(b5b13)",
  "-11(b5b13)": "m11(b5b13)",
  "-11b5b13": "m11(b5b13)",
  "-11(-5-13)": "m11(b5b13)",
  "-11-5-13": "m11(b5b13)",
  "m11(b5b9)": "m11(b5b9)",
  "m11b5b9": "m11(b5b9)",
  "m11-5-9": "m11(b5b9)",
  "m11(-5-9)": "m11(b5b9)",
  "-11(b5b9)": "m11(b5b9)",
  "-11b5b9": "m11(b5b9)",
  "-11-5-9": "m11(b5b9)",
  "-11(-5-9)": "m11(b5b9)",
  "m11(b9)": "m11(b9)",
  "m11b9": "m11(b9)",
  "m11(-9)": "m11(b9)",
  "m11-9": "m11(b9)",
  "-11(b9)": "m11(b9)",
  "-11b9": "m11(b9)",
  "-11(-9)": "m11(b9)",
  "-11-9": "m11(b9)",
  "m11(b9#5)": "m11(b9#5)",
  "m11(#5b9)": "m11(b9#5)",
  "m11#5b9": "m11(b9#5)",
  "m11+5-9": "m11(b9#5)",
  "m11(+5-9)": "m11(b9#5)",
  "-11(#5b9)": "m11(b9#5)",
  "-11#5b9": "m11(b9#5)",
  "m9+5-9": "m11(b9#5)",
  "-11(+5-9)": "m11(b9#5)",
  "m11(b9b13)": "m11(b9b13)",
  "m11b9b13": "m11(b9b13)",
  "m11(-9-13)": "m11(b9b13)",
  "m11-9-13": "m11(b9b13)",
  "-11(b9b13)": "m11(b9b13)",
  "-11b9b13": "m11(b9b13)",
  "-11(-9-13)": "m11(b9b13)",
  "-11-9-13": "m11(b9b13)",
  "m13": "m13",
  "mi13": "m13",
  "min13": "m13",
  "-13": "m13",
  "m13(#11)": "m13(#11)",
  "m13#11": "m13(#11)",
  "m13+11": "m13(#11)",
  "m13(+11)": "m13(#11)",
  "-13(#11)": "m13(#11)",
  "-13#11": "m13(#11)",
  "-13+11": "m13(#11)",
  "-13(+11)": "m13(#11)",
  "m13(#5)": "m13(#5)",
  "m13#5": "m13(#5)",
  "m13+5": "m13(#5)",
  "m13(+5)": "m13(#5)",
  "-13(#5)": "m13(#5)",
  "-13#5": "m13(#5)",
  "-13(+5)": "m13(#5)",
  "m13(#9)": "m13(#9)",
  "m13#9": "m13(#9)",
  "m13(+9)": "m13(#9)",
  "m13+9": "m13(#9)",
  "-13(#9)": "m13(#9)",
  "-13#9": "m13(#9)",
  "-13(+9)": "m13(#9)",
  "-13+9": "m13(#9)",
  "m13(b5)": "m13(b5)",
  "m13b5": "m13(b5)",
  "m13-5": "m13(b5)",
  "m13(-5)": "m13(b5)",
  "-13(b5)": "m13(b5)",
  "-13b5": "m13(b5)",
  "-13-5": "m13(b5)",
  "-13(-5)": "m13(b5)",
  "m13(b9)": "m13(b9)",
  "m13b9": "m13(b9)",
  "m13-9": "m13(b9)",
  "m13(-9)": "m13(b9)",
  "-13(b13)": "m13(b9)",
  "-13b13": "m13(b9)",
  "-13-13": "m13(b9)",
  "-13(-13)": "m13(b9)",
  "m2": "m2",
  "mi2": "m2",
  "min2": "m2",
  "m(add2)": "m2",
  "madd2": "m2",
  "m4": "m4",
  "m6": "m6",
  "mi6": "m6",
  "min6": "m6",
  "-6": "m6",
  "m6(#5)": "m6(#5)",
  "m6(9)": "m6(9)",
  "m6add9": "m6(9)",
  "m6(add9)": "m6(9)",
  "m69": "m6(9)",
  "m6(ma7)": "m6(ma7)",
  "m6(M7)": "m6(ma7)",
  "m6(+7)": "m6(ma7)",
  "m6M7": "m6(ma7)",
  "m6+7": "m6(ma7)",
  "m7": "m7",
  "mi7": "m7",
  "min7": "m7",
  "-7": "m7",
  "m7(#11)": "m7(#11)",
  "m7#11": "m7(#11)",
  "m7+11": "m7(#11)",
  "m7(+11)": "m7(#11)",
  "-7(#11)": "m7(#11)",
  "-7#11": "m7(#11)",
  "-7+11": "m7(#11)",
  "-7(+11)": "m7(#11)",
  "m7(#5)": "m7(#5)",
  "m7#5": "m7(#5)",
  "m7+5": "m7(#5)",
  "m7(+5)": "m7(#5)",
  "-7(#5)": "m7(#5)",
  "-7#5": "m7(#5)",
  "-7(+5)": "m7(#5)",
  "m7(#9)": "m7(#9)",
  "m7#9": "m7(#9)",
  "m7+9": "m7(#9)",
  "m7(+9)": "m7(#9)",
  "-7(#9)": "m7(#9)",
  "-7#9": "m7(#9)",
  "-7(+9)": "m7(#9)",
  "m7(11)": "m7(11)",
  "m7(4)": "m7(4)",
  "m7(add4)": "m7(4)",
  "m7(add11)": "m7(4)",
  "m74": "m7(4)",
  "m7(6)": "m7(6)",
  "m7(add6)": "m7(add6)",
  "m7(b13)": "m7(b13)",
  "m7b13": "m7(b13)",
  "m7-13": "m7(b13)",
  "m7(-13)": "m7(b13)",
  "-7(b13)": "m7(b13)",
  "-7b13": "m7(b13)",
  "-7-13": "m7(b13)",
  "-7(-13)": "m7(b13)",
  "m7(b5)": "m7(b5)",
  "m7b5": "m7(b5)",
  "m7-5": "m7(b5)",
  "m7(-5)": "m7(b5)",
  "-7(b5)": "m7(b5)",
  "-7b5": "m7(b5)",
  "-7(-5)": "m7(b5)",
  "m7(b9)": "m7(b9)",
  "m7b9": "m7(b9)",
  "m7-9": "m7(b9)",
  "m7(-9)": "m7(b9)",
  "-7(b9)": "m7(b9)",
  "-7b9": "m7(b9)",
  "-7(-9)": "m7(b9)",
  "m7(no3)": "m7(no3)",
  "min7(no3)": "m7(no3)",
  "m7sus4": "m7sus4",
  "m7sus": "m7sus4",
  "m9": "m9",
  "mi9": "m9",
  "min9": "m9",
  "-9": "m9",
  "m9(#11)": "m9(#11)",
  "m9#11": "m9(#11)",
  "m9+11": "m9(#11)",
  "m9(+11)": "m9(#11)",
  "-9(#11)": "m9(#11)",
  "-9#11": "m9(#11)",
  "-9+11": "m9(#11)",
  "-9(+11)": "m9(#11)",
  "m9(#5)": "m9(#5)",
  "m9#5": "m9(#5)",
  "m9(+5)": "m9(#5)",
  "-9(#5)": "m9(#5)",
  "-9#5": "m9(#5)",
  "-9(+5)": "m9(#5)",
  "m9(#7)": "m9(#7)",
  "m9(b13)": "m9(b13)",
  "m9b13": "m9(b13)",
  "m9-13": "m9(b13)",
  "m9(-13)": "m9(b13)",
  "-9(b13)": "m9(b13)",
  "-9b13": "m9(b13)",
  "-9-13": "m9(b13)",
  "-9(-13)": "m9(b13)",
  "m9(b5)": "m9(b5)",
  "m9b5": "m9(b5)",
  "m9-5": "m9(b5)",
  "m9(-5)": "m9(b5)",
  "-9(b5)": "m9(b5)",
  "-9b5": "m9(b5)",
  "-9(-5)": "m9(b5)",
  "m9(ma7)": "m9(ma7)",
  "m9(maj7)": "m9(ma7)",
  "m9(+7)": "m9(ma7)",
  "m9(M7)": "m9(ma7)",
  "m9M7": "m9(ma7)",
  "ma9": "ma9",
  "maj9": "ma9",
  "ma11": "ma11",
  "11(#7)": "ma11",
  "11#7": "ma11",
  "11+7": "ma11",
  "11(+7)": "ma11",
  "+11": "ma11",
  "M11": "ma11",
  "ma11(#5)": "ma11(#5)",
  "maj11#5": "ma11(#5)",
  "maj11+5": "ma11(#5)",
  "maj11(+5)": "ma11(#5)",
  "+11(#5)": "ma11(#5)",
  "+11#5": "ma11(#5)",
  "M11+5": "ma11(#5)",
  "+11(+5)": "ma11(#5)",
  "maj11(#5)": "ma11(#5)",
  "ma11#5": "ma11(#5)",
  "ma11+5": "ma11(#5)",
  "ma11(+5)": "ma11(#5)",
  "ma11(#9)": "ma11(#9)",
  "maj11#9": "ma11(#9)",
  "maj11+9": "ma11(#9)",
  "maj11(+9)": "ma11(#9)",
  "+11(#9)": "ma11(#9)",
  "+11#9": "ma11(#9)",
  "M11+9": "ma11(#9)",
  "+11(+9)": "ma11(#9)",
  "maj11(#9)": "ma11(#9)",
  "ma11#9": "ma11(#9)",
  "ma11+9": "ma11(#9)",
  "ma11(+9)": "ma11(#9)",
  "ma11(b13)": "ma11(b13)",
  "maj11b13": "ma11(b13)",
  "maj11-13": "ma11(b13)",
  "maj11(-13)": "ma11(b13)",
  "+11(b13)": "ma11(b13)",
  "+11b13": "ma11(b13)",
  "M11-13": "ma11(b13)",
  "+11(-13)": "ma11(b13)",
  "maj11(b13)": "ma11(b13)",
  "ma11b13": "ma11(b13)",
  "ma11-13": "ma11(b13)",
  "ma11(-13)": "ma11(b13)",
  "ma11(b5)": "ma11(b5)",
  "maj11b5": "ma11(b5)",
  "maj11-5": "ma11(b5)",
  "maj11(-5)": "ma11(b5)",
  "+11(b5)": "ma11(b5)",
  "+11b5": "ma11(b5)",
  "M11-5": "ma11(b5)",
  "+11(-5)": "ma11(b5)",
  "maj11(b5)": "ma11(b5)",
  "ma11b5": "ma11(b5)",
  "ma11-5": "ma11(b5)",
  "ma11(-5)": "ma11(b5)",
  "ma11(b9)": "ma11(b9)",
  "maj11b9": "ma11(b9)",
  "maj11(-9)": "ma11(b9)",
  "maj11-9": "ma11(b9)",
  "+11(b9)": "ma11(b9)",
  "+11b9": "ma11(b9)",
  "M11(-9)": "ma11(b9)",
  "+11-9": "ma11(b9)",
  "maj11(b9)": "ma11(b9)",
  "ma11b9": "ma11(b9)",
  "ma11(-9)": "ma11(b9)",
  "ma11-9": "ma11(b9)",
  "ma13": "ma13",
  "13(#7)": "ma13",
  "+13": "ma13",
  "M13": "ma13",
  "ma13(#11)": "ma13(#11)",
  "maj13#11": "ma13(#11)",
  "maj13+11": "ma13(#11)",
  "maj13(+11)": "ma13(#11)",
  "+13(#11)": "ma13(#11)",
  "+13#11": "ma13(#11)",
  "M13+11": "ma13(#11)",
  "+13(+11)": "ma13(#11)",
  "maj13(#11)": "ma13(#11)",
  "ma7#11": "ma9(#11)",
  "ma13+11": "ma13(#11)",
  "ma13(+11)": "ma13(#11)",
  "ma13(#11#5)": "ma13(#11#5)",
  "maj13#11#5": "ma13(#11#5)",
  "maj13+11+5": "ma13(#11#5)",
  "maj13(+11+5)": "ma13(#11#5)",
  "+13(#11#5)": "ma13(#11#5)",
  "+13#11#5": "ma13(#11#5)",
  "M13+11+5": "ma13(#11#5)",
  "+13(+11+5)": "ma13(#11#5)",
  "maj13(#11#5)": "ma13(#11#5)",
  "ma7#11#5": "ma9(#11#5)",
  "ma13+11+5": "ma13(#11#5)",
  "ma13(+11+5)": "ma13(#11#5)",
  "ma13(#5)": "ma13(#5)",
  "maj13#5": "ma13(#5)",
  "maj13+5": "ma13(#5)",
  "maj13(+5)": "ma13(#5)",
  "+13(#5)": "ma13(#5)",
  "+13#5": "ma13(#5)",
  "M13+5": "ma13(#5)",
  "+13(+5)": "ma13(#5)",
  "maj13(#5)": "ma13(#5)",
  "ma13#5": "ma13(#5)",
  "ma13+5": "ma13(#5)",
  "ma13(+5)": "ma13(#5)",
  "ma13(#9)": "ma13(#9)",
  "maj13#9": "ma13(#9)",
  "maj13(+9)": "ma13(#9)",
  "maj13+9": "ma13(#9)",
  "+13(#9)": "ma13(#9)",
  "+13#9": "ma13(#9)",
  "M13(+9)": "ma13(#9)",
  "+13+9": "ma13(#9)",
  "maj13(#9)": "ma13(#9)",
  "ma13#9": "ma13(#9)",
  "ma13(+9)": "ma13(#9)",
  "ma13+9": "ma13(#9)",
  "ma13(#9#5)": "ma13(#9#5)",
  "maj13#9#5": "ma13(#9#5)",
  "maj13(+9+5)": "ma13(#9#5)",
  "maj13+9+5": "ma13(#9#5)",
  "+13(#9#5)": "ma13(#9#5)",
  "+13#9#5": "ma13(#9#5)",
  "M13(+9+5)": "ma13(#9#5)",
  "+13+9+5": "ma13(#9#5)",
  "maj13(#9#5)": "ma13(#9#5)",
  "ma7#9#5": "ma13(#9#5)",
  "ma13(+9+5)": "ma13(#9#5)",
  "ma13+9+5": "ma13(#9#5)",
  "ma13(b5)": "ma13(b5)",
  "maj13b5": "ma13(b5)",
  "maj13-5": "ma13(b5)",
  "maj13(-5)": "ma13(b5)",
  "+13(b5)": "ma13(b5)",
  "+13b5": "ma13(b5)",
  "M13-5": "ma13(b5)",
  "+13(-5)": "ma13(b5)",
  "maj13(b5)": "ma13(b5)",
  "ma13b5": "ma13(b5)",
  "ma13-5": "ma13(b5)",
  "ma13(-5)": "ma13(b5)",
  "ma13(b9)": "ma13(b9)",
  "maj13b9": "ma13(b9)",
  "maj13-9": "ma13(b9)",
  "maj13(-9)": "ma13(b9)",
  "+13(b9)": "ma13(b9)",
  "+13b9": "ma13(b9)",
  "M13-9": "ma13(b9)",
  "+13(-9)": "ma13(b9)",
  "maj13(b9)": "ma13(b9)",
  "ma13b9": "ma13(b9)",
  "ma13-9": "ma13(b9)",
  "ma13(-9)": "ma13(b9)",
  "ma13(b9#5)": "ma13(b9#5)",
  "maj13b9#5": "ma13(b9#5)",
  "maj13-9+5": "ma13(b9#5)",
  "maj13(-9+5)": "ma13(b9#5)",
  "+13(b9#5)": "ma13(b9#5)",
  "+13b9#5": "ma13(b9#5)",
  "M13-9+5": "ma13(b9#5)",
  "+13(-9+5)": "ma13(b9#5)",
  "maj13(b9#5)": "ma13(b9#5)",
  "ma7b9#5": "ma13(b9#5)",
  "ma13-9+5": "ma13(b9#5)",
  "ma13(-9+5)": "ma13(b9#5)",
  "ma6": "ma6",
  "ma6(9)": "ma6(9)",
  "ma69": "ma6(9)",
  "ma7": "ma7",
  "+7": "ma7",
  "#7": "ma7",
  "M7": "ma7",
  "Maj7": "ma7",
  "maj7": "ma7",
  "(triangle)": "ma7",
  "ma7(#11)": "ma7(#11)",
  "maj7#11": "ma7(#11)",
  "maj7+11": "ma7(#11)",
  "maj7(+11)": "ma7(#11)",
  "+7(#11)": "ma7(#11)",
  "+7#11": "ma7(#11)",
  "M7+11": "ma7(#11)",
  "+7(+11)": "ma7(#11)",
  "maj7(#11)": "ma7(#11)",
  "ma7+11": "ma7(#11)",
  "ma7(+11)": "ma7(#11)",
  "ma7(#4)": "ma7(#4)",
  "ma7(#5)": "ma7(#5)",
  "maj7#5": "ma7(#5)",
  "maj7+5": "ma7(#5)",
  "maj7(+5)": "ma7(#5)",
  "+7(#5)": "ma7(#5)",
  "+7#5": "ma7(#5)",
  "M7+5": "ma7(#5)",
  "+7(+5)": "ma7(#5)",
  "maj7(#5)": "ma7(#5)",
  "ma7#5": "ma7(#5)",
  "ma7+5": "ma7(#5)",
  "ma7(+5)": "ma7(#5)",
  "aug(M7)": "ma7(#5)",
  "aug(+7)": "ma7(#5)",
  "aug(ma7)": "ma7(#5)",
  "aug(maj7)": "ma7(#5)",
  "ma7(#9)": "ma7(#9)",
  "maj7#9": "ma7(#9)",
  "maj7+9": "ma7(#9)",
  "maj7(+9)": "ma7(#9)",
  "+7(#9)": "ma7(#9)",
  "+7#9": "ma7(#9)",
  "M7+9": "ma7(#9)",
  "+7(+9)": "ma7(#9)",
  "maj7(#9)": "ma7(#9)",
  "ma7#9": "ma7(#9)",
  "ma7+9": "ma7(#9)",
  "ma7(+9)": "ma7(#9)",
  "ma7(b13)": "ma7(b13)",
  "maj7b13": "ma7(b13)",
  "maj7-13": "ma7(b13)",
  "maj7(-13)": "ma7(b13)",
  "+7(b13)": "ma7(b13)",
  "+7b13": "ma7(b13)",
  "M7-13": "ma7(b13)",
  "+7(-13)": "ma7(b13)",
  "maj7(b13)": "ma7(b13)",
  "ma7b13": "ma9(b13)",
  "ma7-13": "ma7(b13)",
  "ma7(-13)": "ma7(b13)",
  "ma7(b5)": "ma7(b5)",
  "maj7b5": "ma7(b5)",
  "maj7-5": "ma7(b5)",
  "maj7(-5)": "ma7(b5)",
  "+7(b5)": "ma7(b5)",
  "+7b5": "ma7(b5)",
  "M7-5": "ma7(b5)",
  "+7(-5)": "ma7(b5)",
  "maj7(b5)": "ma7(b5)",
  "ma7b5": "ma7(b5)",
  "ma7-5": "ma7(b5)",
  "ma7(-5)": "ma7(b5)",
  "ma7(b9)": "ma7(b9)",
  "maj7b9": "ma7(b9)",
  "maj7-9": "ma7(b9)",
  "maj7(-9)": "ma7(b9)",
  "+7(b9)": "ma7(b9)",
  "+7b9": "ma7(b9)",
  "M7-9": "ma7(b9)",
  "+7(-9)": "ma7(b9)",
  "maj7(b9)": "ma7(b9)",
  "ma7b9": "ma7(b9)",
  "ma7-9": "ma7(b9)",
  "ma7(-9)": "ma7(b9)",
  "ma7(no3)": "ma7(no3)",
  "9(#7)": "ma9",
  "+9": "ma9",
  "M9": "ma9",
  "ma9(#11)": "ma9(#11)",
  "maj9#11": "ma9(#11)",
  "maj9+11": "ma9(#11)",
  "maj9(+11)": "ma9(#11)",
  "+9(#11)": "ma9(#11)",
  "+9#11": "ma9(#11)",
  "M9+11": "ma9(#11)",
  "+9(+11)": "ma9(#11)",
  "maj9(#11)": "ma9(#11)",
  "ma9+11": "ma9(#11)",
  "ma9(+11)": "ma9(#11)",
  "ma9(#11#5)": "ma9(#11#5)",
  "maj9#11#5": "ma9(#11#5)",
  "maj9+11+5": "ma9(#11#5)",
  "maj9(+11+5)": "ma9(#11#5)",
  "+9(#11#5)": "ma9(#11#5)",
  "+9#11#5": "ma9(#11#5)",
  "M9+11+5": "ma9(#11#5)",
  "+9(+11+5)": "ma9(#11#5)",
  "maj9(#11#5)": "ma9(#11#5)",
  "ma9+11+5": "ma9(#11#5)",
  "ma9(+11+5)": "ma9(#11#5)",
  "ma9(#4)": "ma9(#4)",
  "ma9(#5)": "ma9(#5)",
  "maj9#5": "ma9(#5)",
  "maj9+5": "ma9(#5)",
  "maj9(+5)": "ma9(#5)",
  "+9(#5)": "ma9(#5)",
  "+9#5": "ma9(#5)",
  "M9+5": "ma9(#5)",
  "+9(+5)": "ma9(#5)",
  "maj9(#5)": "ma9(#5)",
  "ma9#5": "ma9(#5)",
  "ma9+5": "ma9(#5)",
  "ma9(+5)": "ma9(#5)",
  "ma9(13)": "ma9(13)",
  "ma9(b13)": "ma9(b13)",
  "maj9b13": "ma9(b13)",
  "maj9-13": "ma9(b13)",
  "maj9(-13)": "ma9(b13)",
  "+9(b13)": "ma9(b13)",
  "+9b13": "ma9(b13)",
  "M9-13": "ma9(b13)",
  "+9(-13)": "ma9(b13)",
  "maj9(b13)": "ma9(b13)",
  "ma9-13": "ma9(b13)",
  "ma9(-13)": "ma9(b13)",
  "ma9(b13#5)": "ma9(b13#5)",
  "maj9b13#5": "ma9(b13#5)",
  "maj9-13+5": "ma9(b13#5)",
  "maj9(-13+5)": "ma9(b13#5)",
  "+9(b13#5)": "ma9(b13#5)",
  "+9b13#5": "ma9(b13#5)",
  "M9-13+5": "ma9(b13#5)",
  "+9(-13+5)": "ma9(b13#5)",
  "maj9(b13#5)": "ma9(b13#5)",
  "ma7b13#5": "ma9(b13#5)",
  "ma9-13+5": "ma9(b13#5)",
  "ma9(-13+5)": "ma9(b13#5)",
  "ma9(b5)": "ma9(b5)",
  "maj9b5": "ma9(b5)",
  "maj9-5": "ma9(b5)",
  "maj9(-5)": "ma9(b5)",
  "+9(b5)": "ma9(b5)",
  "+9b5": "ma9(b5)",
  "M9-5": "ma9(b5)",
  "+9(-5)": "ma9(b5)",
  "maj9(b5)": "ma9(b5)",
  "ma9b5": "ma9(b5)",
  "ma9-5": "ma9(b5)",
  "ma9(-5)": "ma9(b5)",
  "sus": "sus",
  "sus4": "sus",
  "sus(no5)": "sus(no5)",
  "sus#42": "sus#42"
};

export default mapping;