
// Generated by peggy v. 2.0.1 (ts-pegjs plugin v. 3.0.0 )
//
// https://peggyjs.org/   https://github.com/metadevpro/ts-pegjs

"use strict";

export interface FilePosition {
  offset: number;
  line: number;
  column: number;
}

export interface FileRange {
  start: FilePosition;
  end: FilePosition;
  source: string;
}

export interface LiteralExpectation {
  type: "literal";
  text: string;
  ignoreCase: boolean;
}

export interface ClassParts extends Array<string | ClassParts> {}

export interface ClassExpectation {
  type: "class";
  parts: ClassParts;
  inverted: boolean;
  ignoreCase: boolean;
}

export interface AnyExpectation {
  type: "any";
}

export interface EndExpectation {
  type: "end";
}

export interface OtherExpectation {
  type: "other";
  description: string;
}

export type Expectation = LiteralExpectation | ClassExpectation | AnyExpectation | EndExpectation | OtherExpectation;

function peg$padEnd(str: string, targetLength: number, padString: string) {
  padString = padString || ' ';
  if (str.length > targetLength) {
    return str;
  }
  targetLength -= str.length;
  padString += padString.repeat(targetLength);
  return str + padString.slice(0, targetLength);
}

export class PeggySyntaxError extends Error {
  public static buildMessage(expected: Expectation[], found: string | null) {
    function hex(ch: string): string {
      return ch.charCodeAt(0).toString(16).toUpperCase();
    }

    function literalEscape(s: string): string {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/"/g,  "\\\"")
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g,            (ch) => "\\x0" + hex(ch) )
        .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x"  + hex(ch) );
    }

    function classEscape(s: string): string {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/\]/g, "\\]")
        .replace(/\^/g, "\\^")
        .replace(/-/g,  "\\-")
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g,            (ch) => "\\x0" + hex(ch) )
        .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x"  + hex(ch) );
    }

    function describeExpectation(expectation: Expectation) {
      switch (expectation.type) {
        case "literal":
          return "\"" + literalEscape(expectation.text) + "\"";
        case "class":
          const escapedParts = expectation.parts.map((part) => {
            return Array.isArray(part)
              ? classEscape(part[0] as string) + "-" + classEscape(part[1] as string)
              : classEscape(part);
          });

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        case "any":
          return "any character";
        case "end":
          return "end of input";
        case "other":
          return expectation.description;
      }
    }

    function describeExpected(expected1: Expectation[]) {
      const descriptions = expected1.map(describeExpectation);
      let i: number;
      let j: number;

      descriptions.sort();

      if (descriptions.length > 0) {
        for (i = 1, j = 1; i < descriptions.length; i++) {
          if (descriptions[i - 1] !== descriptions[i]) {
            descriptions[j] = descriptions[i];
            j++;
          }
        }
        descriptions.length = j;
      }

      switch (descriptions.length) {
        case 1:
          return descriptions[0];

        case 2:
          return descriptions[0] + " or " + descriptions[1];

        default:
          return descriptions.slice(0, -1).join(", ")
            + ", or "
            + descriptions[descriptions.length - 1];
      }
    }

    function describeFound(found1: string | null) {
      return found1 ? "\"" + literalEscape(found1) + "\"" : "end of input";
    }

    return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
  }

  public message: string;
  public expected: Expectation[];
  public found: string | null;
  public location: FileRange;
  public name: string;

  constructor(message: string, expected: Expectation[], found: string | null, location: FileRange) {
    super();
    this.message = message;
    this.expected = expected;
    this.found = found;
    this.location = location;
    this.name = "PeggySyntaxError";

    if (typeof (Object as any).setPrototypeOf === "function") {
      (Object as any).setPrototypeOf(this, PeggySyntaxError.prototype);
    } else {
      (this as any).__proto__ = PeggySyntaxError.prototype;
    }
    if (typeof (Error as any).captureStackTrace === "function") {
      (Error as any).captureStackTrace(this, PeggySyntaxError);
    }
  }

  format(sources: { grammarSource?: string; text: string }[]): string {
    let str = 'Error: ' + this.message;
    if (this.location) {
      let src: string[] | null = null;
      let k;
      for (k = 0; k < sources.length; k++) {
        if (sources[k].grammarSource === this.location.source) {
          src = sources[k].text.split(/\r\n|\n|\r/g);
          break;
        }
      }
      let s = this.location.start;
      let loc = this.location.source + ':' + s.line + ':' + s.column;
      if (src) {
        let e = this.location.end;
        let filler = peg$padEnd('', s.line.toString().length, ' ');
        let line = src[s.line - 1];
        let last = s.line === e.line ? e.column : line.length + 1;
        str += '\n --> ' + loc + '\n' + filler + ' |\n' + s.line + ' | ' + line + '\n' + filler + ' | ' +
          peg$padEnd('', s.column - 1, ' ') +
          peg$padEnd('', last - s.column, '^');
      } else {
        str += '\n at ' + loc;
      }
    }
    return str;
  }
}

function peg$parse(input: string, options?: ParseOptions) {
  options = options !== undefined ? options : {};

  const peg$FAILED: Readonly<any> = {};
  const peg$source = options.grammarSource;

  const peg$startRuleFunctions: {[id: string]: any} = { ChordSheet: peg$parseChordSheet };
  let peg$startRuleFunction: () => any = peg$parseChordSheet;

  const peg$c0 = function(lines: any, line: any): any {
        return {
          type: 'chordSheet',
          lines: [...lines, line],
        };
      };
  const peg$c1 = function(line: any): any {
        return line;
      };
  const peg$c2 = function(lyrics: any, tokens: any, chords: any, comment: any): any {
        return {
          type: 'line',
          items: [
            lyrics ? { type: 'chordLyricsPair', chords: '', lyrics } : null,
            ...tokens,
            chords ? { type: 'chordLyricsPair', chords, lyrics: '' } : null,
            comment ? { type: 'comment', comment } : null,
          ].filter(x => x),
        };
      };
  const peg$c3 = function(lyrics: any): any {
        return { type: 'chordLyricsPair', chords: '', lyrics };
      };
  const peg$c4 = "#";
  const peg$c5 = peg$literalExpectation("#", false);
  const peg$c6 = /^[^\r\n]/;
  const peg$c7 = peg$classExpectation(["\r", "\n"], true, false);
  const peg$c8 = function(comment: any): any {
        return comment;
      };
  const peg$c9 = function(chords: any, lyrics: any, space: any): any {
        return {
          type: 'chordLyricsPair',
          chords: chords || '',
          lyrics: lyrics + (space || ''),
        };
      };
  const peg$c10 = function(lyrics: any): any {
        return lyrics.map(c => c.char || c).join('');
      };
  const peg$c11 = "[";
  const peg$c12 = peg$literalExpectation("[", false);
  const peg$c13 = "]";
  const peg$c14 = peg$literalExpectation("]", false);
  const peg$c15 = function(chords: any): any {
        return chords.map(c => c.char || c).join('');
      };
  const peg$c16 = /^[^\]\r\n]/;
  const peg$c17 = peg$classExpectation(["]", "\r", "\n"], true, false);
  const peg$c18 = "\\";
  const peg$c19 = peg$literalExpectation("\\", false);
  const peg$c20 = function(): any { return { type: 'char', char: '\\' }; };
  const peg$c21 = function(): any { return { type: 'char', char: ']'  }; };
  const peg$c22 = function(sequence: any): any {
        return sequence;
      };
  const peg$c23 = "%{";
  const peg$c24 = peg$literalExpectation("%{", false);
  const peg$c25 = "}";
  const peg$c26 = peg$literalExpectation("}", false);
  const peg$c27 = function(variableName: any, valueTest: any, expressions: any): any {
        return {
          type: 'ternary',
          variable: variableName.length > 0 ? variableName : null,
          valueTest,
          ...expressions,
          location: location().start,
        };
      };
  const peg$c28 = "=";
  const peg$c29 = peg$literalExpectation("=", false);
  const peg$c30 = function(testValue: any): any {
        return testValue;
      };
  const peg$c31 = "|";
  const peg$c32 = peg$literalExpectation("|", false);
  const peg$c33 = function(trueExpression: any, falseExpression: any): any {
        return {
          type: 'ternary',
          trueExpression,
          falseExpression,
          location: location().start,
        };
      };
  const peg$c34 = function(falseExpression: any): any {
        return falseExpression;
      };
  const peg$c35 = /^[a-zA-Z0-9\-_]/;
  const peg$c36 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "-", "_"], false, false);
  const peg$c37 = function(): any { return { type: 'char', char: ']'    }; };
  const peg$c38 = function(): any { return { type: 'char', char: '|'    }; };
  const peg$c39 = function(): any { return { type: 'char', char: '\x7d' }; };
  const peg$c40 = /^[^|[\]\\{}%#\r\n\t ]/;
  const peg$c41 = peg$classExpectation(["|", "[", "]", "\\", "{", "}", "%", "#", "\r", "\n", "\t", " "], true, false);
  const peg$c42 = function(): any { return { type: 'char', char: '\\'   }; };
  const peg$c43 = function(): any { return { type: 'char', char: '['    }; };
  const peg$c44 = "{";
  const peg$c45 = peg$literalExpectation("{", false);
  const peg$c46 = function(): any { return { type: 'char', char: '\x7b' }; };
  const peg$c47 = "%";
  const peg$c48 = peg$literalExpectation("%", false);
  const peg$c49 = function(): any { return { type: 'char', char: '%'    }; };
  const peg$c50 = function(): any { return { type: 'char', char: '#'    }; };
  const peg$c51 = function(tagName: any, tagColonWithValue: any): any {
        return {
          type: 'tag',
          name: tagName,
          value: tagColonWithValue,
          location: location().start,
        };
      };
  const peg$c52 = ":";
  const peg$c53 = peg$literalExpectation(":", false);
  const peg$c54 = function(tagValue: any): any {
        return tagValue.map(c => c.char || c).join('');
      };
  const peg$c55 = /^[a-zA-Z\-_]/;
  const peg$c56 = peg$classExpectation([["a", "z"], ["A", "Z"], "-", "_"], false, false);
  const peg$c57 = /^[^}\\\r\n]/;
  const peg$c58 = peg$classExpectation(["}", "\\", "\r", "\n"], true, false);
  const peg$c59 = peg$otherExpectation("whitespace");
  const peg$c60 = /^[ \t\n\r]/;
  const peg$c61 = peg$classExpectation([" ", "\t", "\n", "\r"], false, false);
  const peg$c62 = peg$otherExpectation("space");
  const peg$c63 = /^[ \t]/;
  const peg$c64 = peg$classExpectation([" ", "\t"], false, false);
  const peg$c65 = "\n";
  const peg$c66 = peg$literalExpectation("\n", false);
  const peg$c67 = "\r";
  const peg$c68 = peg$literalExpectation("\r", false);

  let peg$currPos = 0;
  let peg$savedPos = 0;
  const peg$posDetailsCache = [{ line: 1, column: 1 }];
  let peg$maxFailPos = 0;
  let peg$maxFailExpected: Expectation[] = [];
  let peg$silentFails = 0;

  let peg$result;

  if (options.startRule !== undefined) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text(): string {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location(): FileRange {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description: string, location1?: FileRange) {
    location1 = location1 !== undefined
      ? location1
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location1
    );
  }

  function error(message: string, location1?: FileRange) {
    location1 = location1 !== undefined
      ? location1
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location1);
  }

  function peg$literalExpectation(text1: string, ignoreCase: boolean): LiteralExpectation {
    return { type: "literal", text: text1, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts: ClassParts, inverted: boolean, ignoreCase: boolean): ClassExpectation {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation(): AnyExpectation {
    return { type: "any" };
  }

  function peg$endExpectation(): EndExpectation {
    return { type: "end" };
  }

  function peg$otherExpectation(description: string): OtherExpectation {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos: number) {
    let details = peg$posDetailsCache[pos];
    let p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  function peg$computeLocation(startPos: number, endPos: number): FileRange {
    const startPosDetails = peg$computePosDetails(startPos);
    const endPosDetails = peg$computePosDetails(endPos);

    return {
      source: peg$source,
      start: {
        offset: startPos,
        line: startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line: endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected1: Expectation) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected1);
  }

  function peg$buildSimpleError(message: string, location1: FileRange) {
    return new PeggySyntaxError(message, [], "", location1);
  }

  function peg$buildStructuredError(expected1: Expectation[], found: string | null, location1: FileRange) {
    return new PeggySyntaxError(
      PeggySyntaxError.buildMessage(expected1, found),
      expected1,
      found,
      location1
    );
  }

  function peg$parseChordSheet(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseLineWithNewline();
    while (s2 as any !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parseLineWithNewline();
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseLine();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c0(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLineWithNewline(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseLine();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseNewLine();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLine(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseLyrics();
    if (s2 as any === peg$FAILED) {
      s2 = null;
    }
    if (s2 as any !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 as any !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseToken();
      while (s3 as any !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseToken();
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseChord();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseComment();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            s5 = [];
            s6 = peg$parseSpace();
            while (s6 as any !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parseSpace();
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c2(s1, s2, s3, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseToken(): any {
    let s0, s1;

    s0 = peg$parseTag();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseChordLyricsPair();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parseMetaTernary();
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseLyrics();
          if (s1 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c3(s1);
          }
          s0 = s1;
        }
      }
    }

    return s0;
  }

  function peg$parseComment(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseSpace();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 35) {
        s2 = peg$c4;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c5); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = [];
        if (peg$c6.test(input.charAt(peg$currPos))) {
          s5 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c7); }
        }
        while (s5 as any !== peg$FAILED) {
          s4.push(s5);
          if (peg$c6.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c7); }
          }
        }
        if (s4 as any !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c8(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseChordLyricsPair(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseChord();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = [];
      s4 = peg$parseLyricsChar();
      while (s4 as any !== peg$FAILED) {
        s3.push(s4);
        s4 = peg$parseLyricsChar();
      }
      if (s3 as any !== peg$FAILED) {
        s2 = input.substring(s2, peg$currPos);
      } else {
        s2 = s3;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = [];
        s5 = peg$parseSpace();
        while (s5 as any !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$parseSpace();
        }
        if (s4 as any !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c9(s1, s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLyrics(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseLyricsCharOrSpace();
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseLyricsCharOrSpace();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c10(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseLyricsCharOrSpace(): any {
    let s0;

    s0 = peg$parseLyricsChar();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseSpace();
    }

    return s0;
  }

  function peg$parseChord(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseEscape();
    peg$silentFails--;
    if (s2 as any === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 91) {
        s2 = peg$c11;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c12); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = [];
        s4 = peg$parseChordChar();
        while (s4 as any !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parseChordChar();
        }
        if (s3 as any !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 93) {
            s4 = peg$c13;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c14); }
          }
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c15(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseChordChar(): any {
    let s0, s1, s2, s3;

    if (peg$c16.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c17); }
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseEscape();
      if (s1 as any !== peg$FAILED) {
        s2 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 92) {
          s3 = peg$c18;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c19); }
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s2;
          s3 = peg$c20();
        }
        s2 = s3;
        if (s2 as any === peg$FAILED) {
          s2 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 93) {
            s3 = peg$c13;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c14); }
          }
          if (s3 as any !== peg$FAILED) {
            peg$savedPos = s2;
            s3 = peg$c21();
          }
          s2 = s3;
        }
        if (s2 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c22(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseMetaTernary(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c23) {
      s1 = peg$c23;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c24); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseMetaVariableName();
        if (s4 as any === peg$FAILED) {
          s4 = null;
        }
        if (s4 as any !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseMetaValueTest();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 as any !== peg$FAILED) {
              s6 = peg$parseMetaTernaryTrueFalseExpressions();
              if (s6 as any === peg$FAILED) {
                s6 = null;
              }
              if (s6 as any !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 as any !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 125) {
                    s8 = peg$c25;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c26); }
                  }
                  if (s8 as any !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c27(s3, s4, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetaValueTest(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 61) {
      s1 = peg$c28;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c29); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseMetaTestValue();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c30(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetaTestValue(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseChar();
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseChar();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parseMetaTernaryTrueFalseExpressions(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 124) {
      s1 = peg$c31;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c32); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseMetaExpression();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parseMetaTernaryFalseExpression();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c33(s3, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetaTernaryFalseExpression(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 124) {
      s1 = peg$c31;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c32); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseMetaExpression();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c34(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetaVariableName(): any {
    let s0, s1;

    s0 = [];
    if (peg$c35.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c36); }
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        if (peg$c35.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c36); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMetaExpression(): any {
    let s0, s1, s2, s3;

    s0 = [];
    s1 = peg$currPos;
    s2 = [];
    s3 = peg$parseChar();
    if (s3 as any !== peg$FAILED) {
      while (s3 as any !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseChar();
      }
    } else {
      s2 = peg$FAILED;
    }
    if (s2 as any !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 as any === peg$FAILED) {
      s1 = peg$parseMetaTernary();
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        s1 = peg$currPos;
        s2 = [];
        s3 = peg$parseChar();
        if (s3 as any !== peg$FAILED) {
          while (s3 as any !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseChar();
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 as any !== peg$FAILED) {
          s1 = input.substring(s1, peg$currPos);
        } else {
          s1 = s2;
        }
        if (s1 as any === peg$FAILED) {
          s1 = peg$parseMetaTernary();
        }
      }
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLyricsChar(): any {
    let s0, s1;

    s0 = peg$parseWordChar();
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 93) {
        s1 = peg$c13;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c14); }
      }
      if (s1 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c37();
      }
      s0 = s1;
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 124) {
          s1 = peg$c31;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c32); }
        }
        if (s1 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c38();
        }
        s0 = s1;
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 125) {
            s1 = peg$c25;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c26); }
          }
          if (s1 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c39();
          }
          s0 = s1;
        }
      }
    }

    return s0;
  }

  function peg$parseChar(): any {
    let s0;

    s0 = peg$parseWordChar();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseSpace();
    }

    return s0;
  }

  function peg$parseWordChar(): any {
    let s0, s1, s2, s3;

    if (peg$c40.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c41); }
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseEscape();
      if (s1 as any !== peg$FAILED) {
        s2 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 92) {
          s3 = peg$c18;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c19); }
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s2;
          s3 = peg$c42();
        }
        s2 = s3;
        if (s2 as any === peg$FAILED) {
          s2 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 124) {
            s3 = peg$c31;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c32); }
          }
          if (s3 as any !== peg$FAILED) {
            peg$savedPos = s2;
            s3 = peg$c38();
          }
          s2 = s3;
          if (s2 as any === peg$FAILED) {
            s2 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 91) {
              s3 = peg$c11;
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c12); }
            }
            if (s3 as any !== peg$FAILED) {
              peg$savedPos = s2;
              s3 = peg$c43();
            }
            s2 = s3;
            if (s2 as any === peg$FAILED) {
              s2 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 93) {
                s3 = peg$c13;
                peg$currPos++;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c14); }
              }
              if (s3 as any !== peg$FAILED) {
                peg$savedPos = s2;
                s3 = peg$c37();
              }
              s2 = s3;
              if (s2 as any === peg$FAILED) {
                s2 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 123) {
                  s3 = peg$c44;
                  peg$currPos++;
                } else {
                  s3 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c45); }
                }
                if (s3 as any !== peg$FAILED) {
                  peg$savedPos = s2;
                  s3 = peg$c46();
                }
                s2 = s3;
                if (s2 as any === peg$FAILED) {
                  s2 = peg$currPos;
                  if (input.charCodeAt(peg$currPos) === 125) {
                    s3 = peg$c25;
                    peg$currPos++;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c26); }
                  }
                  if (s3 as any !== peg$FAILED) {
                    peg$savedPos = s2;
                    s3 = peg$c39();
                  }
                  s2 = s3;
                  if (s2 as any === peg$FAILED) {
                    s2 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 37) {
                      s3 = peg$c47;
                      peg$currPos++;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c48); }
                    }
                    if (s3 as any !== peg$FAILED) {
                      peg$savedPos = s2;
                      s3 = peg$c49();
                    }
                    s2 = s3;
                    if (s2 as any === peg$FAILED) {
                      s2 = peg$currPos;
                      if (input.charCodeAt(peg$currPos) === 35) {
                        s3 = peg$c4;
                        peg$currPos++;
                      } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c5); }
                      }
                      if (s3 as any !== peg$FAILED) {
                        peg$savedPos = s2;
                        s3 = peg$c50();
                      }
                      s2 = s3;
                    }
                  }
                }
              }
            }
          }
        }
        if (s2 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c22(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseTag(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c44;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c45); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseTagName();
        if (s4 as any !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parseTagColonWithValue();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 as any !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 125) {
                  s7 = peg$c25;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c26); }
                }
                if (s7 as any !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c51(s3, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTagColonWithValue(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 58) {
      s1 = peg$c52;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c53); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseTagValue();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c54(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTagName(): any {
    let s0, s1;

    s0 = [];
    if (peg$c55.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c56); }
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        if (peg$c55.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c56); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTagValue(): any {
    let s0, s1;

    s0 = [];
    s1 = peg$parseTagValueChar();
    while (s1 as any !== peg$FAILED) {
      s0.push(s1);
      s1 = peg$parseTagValueChar();
    }

    return s0;
  }

  function peg$parseTagValueChar(): any {
    let s0, s1, s2, s3;

    if (peg$c57.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c58); }
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseEscape();
      if (s1 as any !== peg$FAILED) {
        s2 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 92) {
          s3 = peg$c18;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c19); }
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s2;
          s3 = peg$c42();
        }
        s2 = s3;
        if (s2 as any === peg$FAILED) {
          s2 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 125) {
            s3 = peg$c25;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c26); }
          }
          if (s3 as any !== peg$FAILED) {
            peg$savedPos = s2;
            s3 = peg$c39();
          }
          s2 = s3;
          if (s2 as any === peg$FAILED) {
            s2 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 123) {
              s3 = peg$c44;
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c45); }
            }
            if (s3 as any !== peg$FAILED) {
              peg$savedPos = s2;
              s3 = peg$c46();
            }
            s2 = s3;
          }
        }
        if (s2 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c22(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parse_(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = [];
    if (peg$c60.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c61); }
    }
    while (s1 as any !== peg$FAILED) {
      s0.push(s1);
      if (peg$c60.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c61); }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c59); }
    }

    return s0;
  }

  function peg$parseSpace(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = [];
    if (peg$c63.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c64); }
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        if (peg$c63.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c64); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c62); }
    }

    return s0;
  }

  function peg$parseNewLine(): any {
    let s0;

    s0 = peg$parseCarriageReturn();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseLineFeed();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parseCarriageReturnLineFeed();
      }
    }

    return s0;
  }

  function peg$parseCarriageReturnLineFeed(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseCarriageReturn();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseLineFeed();
      if (s2 as any !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLineFeed(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 10) {
      s0 = peg$c65;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c66); }
    }

    return s0;
  }

  function peg$parseCarriageReturn(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 13) {
      s0 = peg$c67;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c68); }
    }

    return s0;
  }

  function peg$parseEscape(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 92) {
      s0 = peg$c18;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c19); }
    }

    return s0;
  }

  function peg$parsePound(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 35) {
      s0 = peg$c4;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c5); }
    }

    return s0;
  }

  function peg$parsePercent(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 37) {
      s0 = peg$c47;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c48); }
    }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

export interface ParseOptions {
  filename?: string;
  startRule?: string;
  tracer?: any;
  [key: string]: any;
}
export type ParseFunction = (input: string, options?: ParseOptions) => any;
export const parse: ParseFunction = peg$parse;

