import HtmlFormatter, { CSS, Template } from './html_formatter';
import template from './templates/html_div_formatter';
import { scopeCss } from '../utilities';

export const defaultCss: CSS = {
  '.chord:not(:last-child)': {
    paddingRight: '10px',
  },

  '.paragraph': {
    marginBottom: '1em',
  },

  '.row': {
    display: 'flex',
  },

  '.chord:after': {
    content: '\'\\200b\'',
  },

  '.lyrics:after': {
    content: '\'\\200b\'',
  },
};

/**
 * Generates basic CSS, scoped within the provided selector, to use with output generated by {@link HtmlTableFormatter}
 * @param scope the CSS scope to use, for example `.chordSheetViewer`
 * @returns {string} the CSS string
 */
export function scopedCss(scope: string): string {
  return scopeCss(defaultCss, scope);
}

/**
 * Formats a song into HTML. It uses DIVs to align lyrics with chords, which makes it useful for responsive web pages.
 */
class HtmlDivFormatter extends HtmlFormatter {
  get template(): Template {
    return template;
  }

  get defaultCss(): CSS {
    return defaultCss;
  }
}

export default HtmlDivFormatter;
